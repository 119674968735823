<script lang="ts">
import { ref } from "vue";
import Logo from "~/assets/svgs/min-pros-logo-v1.svg";
import Logo1 from "~/assets/svgs/logo.svg";
export default {
  setup() {
    const IsMenuActive = ref(false);
    function onClick() {
      if (IsMenuActive.value) {
        IsMenuActive.value = false;
      } else {
        IsMenuActive.value = true;
      }
    }

    return {
      LOGO: Logo,
      LOGO1: Logo1,
      onClick,
      IsMenuActive,
    };
  },
};
</script>

<template>
  <header class="h-full w-full">
    <section
      class="w-full"
      :class="
        IsMenuActive
          ? 'bg-white h-screen overflow-auto fixed z-10 lg:overflow-hidden'
          : ''
      "
    >
      <div class="container mx-auto p-4">
        <section
          class="grid grid-cols-13 mt-8"
          :class="IsMenuActive ? 'lg:mt-24' : 'lg:mt-11'"
        >
          <nuxt-link
            to="/"
            class="col-start-1 col-end-5"
            :class="
              !IsMenuActive
                ? 'lg:w-35 lg:h-35  lg:col-end-3'
                : 'lg:w-25 lg:h-25 lg:col-end-2'
            "
          >
            <img
              :src="LOGO"
              class="w-23 h-23"
              :class="!IsMenuActive ? 'lg:w-35 lg:h-35' : 'lg:w-25 lg:h-25'"
              v-if="!IsMenuActive"
            />
            <img
              :src="LOGO1"
              class="w-23 h-23"
              :class="!IsMenuActive ? 'lg:w-35 lg:h-35' : 'lg:w-25 lg:h-25'"
              v-else
            />
          </nuxt-link>

          <div
            class="flex flex-col justify-center space-y-2 w-full lg:w-114"
            :class="
              !IsMenuActive
                ? 'col-start-6 ml-4 lg:ml-0 col-end-10 lg:col-start-3 lg:col-end-8'
                : 'col-start-6 col-end-10 ml-4 lg:col-start-2 lg:col-end-7'
            "
          >
            <nuxt-link to="/">
              <div
                class="font-sans text-lg lg:text-xl2"
                :class="!IsMenuActive ? 'text-gray-600' : ''"
              >
                {{
                  $t(
                    "Министерство просвещения Республики Абхазия"
                  )
                }}
              </div>
            </nuxt-link>
          </div>

          <div
            class="hidden lg:flex flex-row space-x-10 pl-17 font-serif text-gray-600 items-center col-start-8 col-end-11"
            v-if="!IsMenuActive"
          >
            <nuxt-link to="/pressa/accreditation" class="whitespace-nowrap">{{
              $t("Аккредитация")
            }}</nuxt-link>

            <nuxt-link to="/about/staff/">{{ $t("Руководство") }}</nuxt-link>

            <nuxt-link to="/contacts">{{ $t("Контакты") }}</nuxt-link>
          </div>
          <div
            v-if="IsMenuActive"
            class="my-auto mt-8 lg:mt-auto w-full row-start-2 lg:row-start-1 col-start-1 col-end-12 lg:col-start-7 lg:col-end-11 lg:w-129"
          >
            <m-search-field />
          </div>
          <div
            class="flex items-center col-start-10 lg:col-start-12 lg:col-end-13"
          >
            <m-burger
              @click="onClick"
              :isMenuActive="IsMenuActive"
              :color="!IsMenuActive ? '#000000' : '#000000'"
            />
          </div>
        </section>
      </div>
      <m-extended-menu v-if="IsMenuActive" @clickOnLink="onClick" />
    </section>
  </header>
</template>
