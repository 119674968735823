<template>
  <div class="flex flex-col space-y-7">
    <div class="flex-1 min-h-screen">
      <m-header-minimal />
      <main class="pt-10 pb-50">
        <slot />
      </main>
      <m-footer />
    </div>
  </div>
</template>
